<script setup lang="ts">
import type { NuxtUiButtonUi } from "~/types/nuxt/ui/button";
import { Size } from "~/utils/enum/size";
import type { NuxtUiSize } from "~/types/nuxt/ui/size";
import type { NuxtUiVariant } from "~/types/nuxt/ui/variant";
import { Variant } from "~/utils/enum/variant";

const props = withDefaults(
  defineProps<{
    label?: string | number;
    size?: NuxtUiSize;
    variant?: NuxtUiVariant;
    ui?: NuxtUiButtonUi;
  }>(),
  {
    label: "",
    size: Size.Xl,
    variant: Variant.Ghost,
    ui: {
      variant: {},
    } as NuxtUiButtonUi,
  },
);
</script>

<template>
  <UButton
    loading
    :label="String(props.label)"
    :size="props.size as string"
    :ui="props.ui"
    :variant="props.variant as string"
  />
</template>

<style scoped />
